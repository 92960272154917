<template>
  <v-card class="custom-border border fade">
    <v-sheet min-height="85vh" max-height="85vh" class="overflow-y-auto d-flex align-stretch flex-column border">
      <div class="ma-5">
        <h3 class="poppins">Account Settings</h3>
        <h4 class="roboto secondary-1--text mt-5 mx-0">Profile Information</h4>
      </div>
      <PhotoUpload :modal="photoUploadDialog" @close="photoUploadDialog = false" :type="'profile'" :role="'usr'"/>
      <v-form ref="form" class="ma-0">
        <section class="d-flex align-center justify-center my-0">
          <div class="group-avatar-uploader d-flex flex-column align-center justify-center"
            style="width: 110px; height: 110px">
              <a href="#" class="text-decoration-none">
                <v-avatar size="100" @click="photoUploadDialog = true"> 
                  <v-img :src="form.image ? form.image.url : require('@/assets/default-photo.png')" v-on:error="form.image = null" />
                </v-avatar>
              </a>
            </div>
        </section>
        <section class="d-flex align-center justify-center my-0">
          <v-btn color="primary" dense small @click="photoUploadDialog = true" class="fw500 f12 my-2">
              Choose Profile Photo
          </v-btn>
        </section>
        <div class="ma-5">
          <h3 class="poppins">Basic Information</h3>
        </div>
        <v-container>
          <v-row dense>
            <v-col cols="12" md="6">
              <FormTextFieldRequired
                :label="'FIRST NAME *'"
                :value.sync="form.first_name"
                :error="errors.first_name"/>
            </v-col>
            <v-col cols="12" md="6">
              <FormTextFieldRequired
                :label="'LAST NAME *'"
                :value.sync="form.last_name"
                :error="errors.last_name"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <FormTextFieldOptional
                :label="'MIDDLE NAME'"
                :value.sync="form.middle_name" />
            </v-col>
            <v-col cols="12" md="6">
              <FormTextFieldRequired
                :label="'CONTACT NUMBER *'"
                :value.sync="form.contact_number"
                />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <FormTextFieldRequired
                :label="'EMAIL ADDRESS *'"
                :value.sync="form.email"
                :error="errors.email"/>
            </v-col>
            <v-col cols="12" md="6">
              <FormSelectObject
                :label="'CHAPTER *'"
                :items="chapters"
                :value.sync="form.chapter"
                :class="'ma-1'"
                :required="true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-spacer />
      <v-divider class="mt-auto"/>
      <div class="ma-5">
        <v-btn @click="update" color="primary" class="poppins text-capitalize fw600" :loading="loading">
          Save changes
        </v-btn>
        <v-btn @click="resetForm()" color="secondary-4" text class="poppins text-capitalize fw600">Cancel</v-btn>
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
import PhotoUpload from '@/components/main/PhotoUpload.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  components: { PhotoUpload },

  data: () => ({
    items: [
      {text: 'Male', value: 'M'},
      {text: 'Female', value: 'F'},
    ],
    image: null,
    dialog: false,
    loading: false,
    file: null,
    photoUploadDialog: false,
    form: null,
    chapters: []
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
      registration_tables: (state) => state.registration_tables,
      errors: (state) => state.errors,
      chapter: (state) => state.chapter,
    }),
    
  },

  mounted() {
    this.getChapter();
    this.getRegistrationTables().then(res => {
      let _data = {}
      this.registration_tables.forEach(tbl => {
        tbl.custom_registration_fields.forEach(column => {
          if (!_data[tbl.title]) {
            _data[tbl.title] = {};
          }
          _data[tbl.title][column.input_field_name] = '';
        })
      })
      this.user.data = Object.assign({}, { ..._data, ...this.user.data });
      this.form = JSON.parse(JSON.stringify(this.user));

      // Check if first_name is null and show a notification
      if (!this.form.first_name || !this.form.last_name ) {
        this.alertMutation({
          show: true,
          text: 'Please update your profile information to continue!',
          type: 'warning'
        });
      }
    })
  },

  methods: {
    ...mapMutations(['alertMutation', 'userMutation','chapterMutation']),
    ...mapActions(['getRegistrationTables', 'updateProfileDataAction', 'showUserCHapter']),

    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.user));
    },
    
    update() {
      this.loading = true

      // save details
      if(this.$refs.form.validate()) {
        this.updateProfileDataAction(this.form).then(res => {
          setTimeout(() => {
            this.loading = false
            this.alertMutation({
              show: true,
              text: 'Profile has been successfully updated!',
              type: "success"
            });
            this.userMutation(this.form)
          }, 2000);
        })
        .catch(errs => {
          this.loading = false
          this.errors = errs.response.data.errors
        })
      } else {
        this.loading = false
      }
    },
    getChapter() {
      this.loading1 = true;
      this.showUserCHapter().then(res => {
        if (res && res.data && Array.isArray(res.data)) {
          this.chapters = res.data.map(item => ({
            text: item.name,
            value: item.name
          }));
        } else {
          console.error("Invalid data format received from server.");
        }
      }).finally(() => {
        this.loading1 = false;
      });
    }
  }
}
</script>
